import ApiService from '../../services/ApiService';
import { getActivePlaces } from '../../services/locationService';
import { isValidFloat } from '../../utils/utils';

export const GET_ROOMS = 'GET_ROOMS';
export const GET_ROOM = 'GET_ROOM';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const DELETE_ROOM = 'DELETE_ROOM';
export const DELETE_ROOMS = 'DELETE_ROOMS';
export const GET_ROOM_TYPES = 'GET_ROOM_TYPES';
export const GET_ROOM_PROPERTIES = 'GET_ROOM_PROPERTIES';
export const GET_ROOM_PREFERENCE_OPTIONS = 'GET_ROOM_PREFERENCE_OPTIONS';
export const CREATE_ROOM_TYPE = 'CREATE_ROOM_TYPE';
export const UPDATE_ROOM_TYPE = 'UPDATE_ROOM_TYPE';
export const CREATE_ROOM_TYPE_PREFERENCE_OPTION = 'CREATE_ROOM_TYPE_PREFERENCE_OPTION';
export const CLONE_ROOM = 'CLONE_ROOM';
export const GET_ACTIVE_PLACES = 'GET_ACTIVE_PLACES';

export const getRooms = params => {
  return async (dispatch, getState) => {
    const state = getState();
    params = {
      page: 0,
      orderBy: 'showDate',
      orderDirection: 'asc',
      total: 0,
      status: '',
      type: '',
      ...state.rooms,
      ...params,
    };

    const response = await ApiService.get(
      `rooms?${[
        `limit=${params.limit}`,
        `page=${params.page || 0}`,
        `status=${params.status && params.status.length ? params.status.join() : ''}`,
        `type=${params.roomType && params.roomType.length ? params.roomType.join() : ''}`,
        `orderBy=${params.orderBy}`,
        `orderDirection=${params.orderDirection}`,
      ].join('&')}`,
      { withCredentials: true }
    );

    const { rooms, more, total } = response.data;

    dispatch({
      type: GET_ROOMS,
      rooms: mapRoomsFromResponse(rooms),
      moreRoomsLeft: more,
      currentPage: params.page,
      status: params.status,
      roomType: params.roomType,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
      total,
      limit: params.limit,
    });
  };
};

export const getRoom = roomId => {
  return async (dispatch, getState) => {
    const response = await ApiService.get(`rooms/${roomId}`, { withCredentials: true });
    console.log(
      'getRoom',
      printRoomDate(response.data),
      new Date(response.data.showDate).getUTCHours()
    );
    dispatch({
      type: GET_ROOM,
      room: response.data,
    });
  };
};

export function printRoomDate(room) {
  return {
    showDate: room.showDate,
    startDate: room.startDate,
    endDate: room.endDate,
  };
}

export const updateRoom = room => {
  return async (dispatch, getState) => {
    room.center = room.center || {};
    room.center.latitude = isValidFloat(room.latitude)
      ? parseFloat(room.latitude)
      : room.center.latitude;
    room.center.longitude = isValidFloat(room.longitude)
      ? parseFloat(room.longitude)
      : room.center.longitude;
    room.meetupLocation = room.meetupLocation || {};
    room.meetupLocation.latitude = isValidFloat(room.meetupLatitude)
      ? parseFloat(room.meetupLatitude)
      : room.meetupLocation.latitude;
    room.meetupLocation.longitude = isValidFloat(room.meetupLongitude)
      ? parseFloat(room.meetupLongitude)
      : room.meetupLocation.longitude;
    room.groupTypeId = room.groupTypeId || room.groupType ? room.groupType.id : null;
    room.locationTypeId = room.locationTypeId || room.locationType ? room.locationType.id : null;
    const response = await ApiService.put(`rooms/${room.id}`, room, { withCredentials: true }, {});
    dispatch({
      type: UPDATE_ROOM,
      room,
    });
  };
};

export const deleteRoom = roomId => {
  return async (dispatch, getState) => {
    const response = await ApiService.delete(`rooms/${roomId}`, { withCredentials: true }, {});
    dispatch({
      type: DELETE_ROOM,
      roomId,
    });
  };
};

export const deleteRooms = adjective => {
  return async (dispatch, getState) => {
    const response = await ApiService.delete(
      `rooms?adjective=${adjective}`,
      { withCredentials: true },
      {}
    );
    dispatch({
      type: DELETE_ROOMS,
      adjective,
    });
  };
};

export const importRooms = async file => {
  if (!file) {
    return;
  }
  var formData = new FormData();
  formData.append('file', file);
  const response = await ApiService.post('/rooms/import', formData, {
    timeout: 300000,
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const createCloneRoom = async room => {
  try {
    room.groupTypeId = room.groupTypeId || room.groupType ? room.groupType.id : null;
    room.locationTypeId = room.locationTypeId || room.locationType ? room.locationType.id : null;
    const response = await ApiService.post(`/rooms`, room, {
      withCredentials: true,
    });
    return response;
  } catch (e) {
    console.error('Create clone room error:', e);
    throw e;
  }
};

export const importRoomUsers = async (roomId, file, type) => {
  if (!file) {
    return;
  }
  var formData = new FormData();
  formData.append('file', file);
  // formData.append('type', type);
  const response = await ApiService.post(`/rooms/${roomId}/users/import?type=${type}`, formData, {
    timeout: 300000,
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  console.log('importRoomUsers', response.status, response.data);
  return response.data;
};

const mapRoomsFromResponse = rooms => rooms;

export const loadUsers = async roomId => {
  const response = await ApiService.get(`rooms/${roomId}/users`, { withCredentials: true }, {});
  console.log('loadUsers', response.data);
  return response.data;
};

export const getRoomTypes = () => {
  return async (dispatch, getState) => {
    const response = await ApiService.get(`roomTypes`, { withCredentials: true }, {});
    const roomTypes = response.data.roomTypes;
    dispatch({
      type: GET_ROOM_TYPES,
      roomTypes,
    });
  };
};

export const getRoomProperties = () => {
  return async (dispatch, getState) => {
    const response = await ApiService.get(`rooms/properties`, { withCredentials: true }, {});
    const roomProperties = response.data.properties;
    dispatch({
      type: GET_ROOM_PROPERTIES,
      roomProperties,
    });
  };
};

export const getRoomPreferenceOptions = () => {
  return async (dispatch, getState) => {
    const roomPreferenceOptions = await getApiRoomPreferenceOptions();
    console.log('dispatch', GET_ROOM_PREFERENCE_OPTIONS, roomPreferenceOptions);
    dispatch({
      type: GET_ROOM_PREFERENCE_OPTIONS,
      roomPreferenceOptions,
    });
  };
};

export const getApiRoomPreferenceOptions = async () => {
  const response = await ApiService.get(
    `roomTypes/preferenceOptions`,
    { withCredentials: true },
    {}
  );
  const roomPreferenceOptions = response.data.preferenceOptions;
  return roomPreferenceOptions;
};

export const createRoomType = ({ type, name, description, schema }) => {
  return async (dispatch, getState) => {
    console.log('createRoomType', { type, name, description, schema });
    const response = await ApiService.post(
      '/roomTypes',
      { type, name, description, schema },
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: CREATE_ROOM_TYPE,
      roomType: {
        id: response.data.id,
        name,
        description,
        schema,
      },
    });
  };
};

export const createRoomPreferenceOption = ({ name, description }) => {
  return async (dispatch, getState) => {
    console.log('createRoomPreferenceOption', { name, description });
    const response = await ApiService.post(
      '/roomTypes/preferenceOptions',
      { name, description },
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: CREATE_ROOM_TYPE_PREFERENCE_OPTION,
      roomPreferenceOption: { name, description, fields: [], id: response.data.id },
    });
  };
};

export const updateRoomTypeApi = async roomType => {
  console.log('updateRoomType', roomType);
  const response = await ApiService.put(
    `roomTypes/${roomType.id}`,
    roomType,
    { withCredentials: true },
    {}
  );
};

export const updateRoomType = roomType => {
  return async (dispatch, getState) => {
    await updateRoomTypeApi(roomType);
    dispatch(updateRoomTypeState(roomType));
  };
};

export const updateRoomTypeState = roomType => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_ROOM_TYPE,
      roomType,
    });
  };
};

export const cloneRoom = room => {
  return dispatch => {
    dispatch({
      type: CLONE_ROOM,
      room,
    });
  };
};

export const getAllActivePlaces = async => {
  return async dispatch => {
    const activePlaces = await getActivePlaces();
    console.log(activePlaces);
    dispatch({
      type: GET_ACTIVE_PLACES,
      activePlaces,
    });
  };
};
